<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div v-if="!getMenu">
        <div
          class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-2xl p-2 h-screen"
        >
          <div class="text-gray-800 mt-10">
            <a
              v-on:click="$router.go(-1)"
              class="flex items-center space-x-2 text-center cursor-pointer"
              ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
            >
          </div>

          <div class="mt-16">
            <h3 class="text-3xl font-sans font-medium">Contact Information</h3>

            <div
              class="grid gap-4 grid-cols-1 lg:grid-cols-2 md:grid-cols-2 mt-10"
            >
              <div>
                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="Phone Number*"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                </div>
                <h3 class="text-md mt-5 text-gray-700">
                  Keep me updated on news & exclusive offers
                </h3>

                <h3 class="text-2xl font-sans font-medium mt-10">
                  Shipping Address
                </h3>
                <h3 class="text-md mt-3 text-gray-700">
                  For DHL express orders, please fill ALL information in
                  English.
                </h3>
                <div
                  class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="Full Name"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                </div>

                <div
                  class="flex justify-center py-1 sm:py-3 px-3 border-2 rounded mt-5"
                >
                  <input
                    v-model="negative_prompt"
                    placeholder="Address"
                    class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                    type="String"
                  />
                </div>

                <div class="grid gap-4 grid-cols-2">
                  <div
                    class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                  >
                    <input
                      v-model="negative_prompt"
                      placeholder="City"
                      class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                      type="String"
                    />
                  </div>
                  <div
                    class="flex justify-center py-1 sm:py-3 px-3 rounded border-2 mt-5"
                  >
                    <input
                      v-model="negative_prompt"
                      placeholder="Province"
                      class="flex-grow outline-none text-lg bg-transparent text-gray-700"
                      type="String"
                    />
                  </div>
                </div>
                <router-link to="/creditcardtopup" class="flex justify-center">
                  <div
                    class="inline-block mt-16 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
                  >
                    <div
                      class="inline-block text-lg px-2 py-1 sm:px-4 sm:py-4 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                    >
                      CONTINUE TO SHIPPING
                    </div>
                  </div>
                </router-link>
              </div>

              <div class="text-center">
                <img
                  class="w-full"
                  src="https://envato-shoebox-0.imgix.net/1ff1/1c86-0471-421e-8a56-5a11c6afb2fd/bji04650187.jpg?auto=compress%2Cformat&fit=max&mark=https%3A%2F%2Felements-assets.envato.com%2Fstatic%2Fwatermark2.png&markalign=center%2Cmiddle&markalpha=18&w=800&s=67c333542608886dee46fa4e515855ab"
                />
                <h3 class="text-lg font-bold pt-5">
                  FASHION MODEL: @xxdiyar X Be A Nice Human Case
                </h3>
              </div>
            </div>
            <hr class="mt-16 mb-16" />
          </div>
        </div>

        <StickFooter class="mt-44" />
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import { POOL_ADDRESS, VERSION } from "../../config";

import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],

      ref: this.$route.params.ref,
      searchPrompt: null,
      slideList: [
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1126410892519682129/POD_Banner_for_web_02.png",
        },
        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1126407023160987708/POD_Banner_for_web_01.png",
        },

        {
          img: "https://cdn.discordapp.com/attachments/1080058777971212370/1126425212389445673/POD_Banner_for_web_03.png",
        },
      ],
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  computed: mapGetters(["getMenu"]),

  methods: {},
  created() {},
};
</script>
